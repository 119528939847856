import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import SectionedPageContent from '../components/sectionedPageContent/sectionedPageContent'
  
export const query = graphql`
  query PartnersQuery {
    prismic {
      allPartnerss {
        edges {
          node {
            title
            introduction_text
            background_video {
              ... on PRISMIC__ImageLink {
                _linkType
                url
                width
                height
              }
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PartnersBodyText_section {
                type
                label
                primary {
                  section_text
                  section_title
                  short_section_title
                }
              }
              ... on PRISMIC_PartnersBodyCase_studies {
                type
                label
                fields {
                  case_study {
                    ... on PRISMIC_Case_studies {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Partners = ({ data }) => {
  const doc = data.prismic.allPartnerss.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Partners" />
      <SectionedPageContent doc={doc} />
    </React.Fragment>
  )
}

export default Partners
